import Cookies from 'js-cookie';
import { getCookiesServer } from './server/cookies';

export const setCookie = (name: string, value: string, options?: Cookies.CookieAttributes) => {
  Cookies.set(name, value, options);
};

export const getCookies = (names: string[]): Promise<string[]> => {
  if (typeof window === 'undefined') return getCookiesServer(names);
  return new Promise((resolve) => {
    resolve(names.map((item) => Cookies.get(item) ?? ''));
  });
};

export const removeCookie = (name: string) => {
  Cookies.remove(name);
};
