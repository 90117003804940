export const stringify = (params: object): string => {
  const query = new URLSearchParams();

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of value) {
        query.append(key, item);
      }
    } else if (value !== null && value !== '') {
      query.append(key, value);
    }
  }

  return `${query}`;
};
