// utils
import { INTERNAL_ADMIN, HOST_API, cookies, is_test, TEST_VER, HOST_OTP } from '@/shared/config-global';
import { getCookies } from '@/utils/cookies';
import { isSSR } from '@/shared/utils';
import { CustomError } from '@/shared/exception';
// paths
// import { redirect } from 'next/navigation';
import { redirect } from 'next/navigation';
// types
import { GoboardingAuthToken } from '@/types/auth';
import { ErrorResponse } from '@/app/api/types';

import { stringify } from './query-string';

// ----------------------------------------------------------------------

type ApiRequestPath = RequestInfo | URL;

type ApiRequestInit = RequestInit & {
  params?: object;
  requestRole?: 'super-admin' | 'customer';
};

class Api {
  baseUrl?: string;

  isInternal: boolean;
  
  constructor(baseUrl?: string) {
    this.baseUrl = baseUrl;
    this.isInternal = !(baseUrl as string).includes('https://') || (baseUrl as string).includes('http://');
  }

  get(path: ApiRequestPath, { params, ...opts }: ApiRequestInit = {}) {
    return this.request(params ? `${path}?${stringify(params)}` : path, {
      ...opts,
    });
  }

  post(path: ApiRequestPath, { params, ...opts }: ApiRequestInit = {}) {
    return this.request(path, {
      method: 'POST',
      body: params as BodyInit,
      ...opts,
    });
  }

  patch(path: ApiRequestPath, { params, ...opts }: ApiRequestInit = {}) {
    return this.request(path, {
      method: 'PATCH',
      body: params as BodyInit,
      ...opts,
    });
  }

  put(path: ApiRequestPath, { params, ...opts }: ApiRequestInit = {}) {
    return this.request(path, {
      method: 'PUT',
      body: params as BodyInit,
      ...opts,
    });
  }

  delete(path: ApiRequestPath, { params, ...opts }: ApiRequestInit = {}) {
    return this.request(path, {
      method: 'DELETE',
      body: params as BodyInit,
      ...opts,
    });
  }

  async request(path: ApiRequestPath, { body, headers, ...opts }: ApiRequestInit = {}) {
    const isExternal = (path as string).includes('https://') || (path as string).includes('http://');

    let url = `${isExternal ? '' : this.baseUrl}${path}`;
    const auth_url = `${this.baseUrl}${is_test ? `:${TEST_VER}` : ''}${API_ENDPOINTS.auth.login}`;
    let accessToken = null;

    if (is_test && !isExternal && !this.isInternal) {
      url = `${this.baseUrl}:${TEST_VER}${path}`
    }

    console.info(`>>[${isSSR() ? 'SSR' : ''}] `, url);

    if (opts.requestRole === 'super-admin') {
      const { authToken }: GoboardingAuthToken = await fetch(
        auth_url,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...is_test && !this.isInternal && !isExternal && {
              'x-data-source': 'test'
            },
          },
          body: JSON.stringify({
            email: INTERNAL_ADMIN.email,
            password: INTERNAL_ADMIN.password,
          }),
          next: {
            revalidate: 0, // auth token expiration time in the server
          },
        }
      ).then((res) => res.json());
      accessToken = authToken;
    } else {
      const [authToken] = await getCookies([cookies.accessToken.name]);
      accessToken = authToken;
    }

    const response = await fetch(url, {
      method: opts.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...is_test && !this.isInternal && !isExternal && {
          'x-data-source': 'test'
        },
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      },
      body: JSON.stringify(body),
      ...opts,
    }).catch((error) => {
      throw new CustomError(
        'Upss, parece que tienes problemas en tu conexión, por favor reintenta',
        {
          code: error.code,
          status: 500,
        }
      );
    });

    console.info('>> ', response.status);

    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().catch(() => 'Sin contenido');
      }
      return response.text().catch(() => 'Sin contenido');
    }

    const error = await handleErrorResponse(response, accessToken);
    throw new CustomError(error.message, {
      code: error.code,
      status: response.status,
    });
  }
}

const api = new Api(HOST_API);

export const otpApi = new Api(HOST_OTP);

export const internalApi = new Api('/api');

export default api;

export const API_ENDPOINTS = {
  auth: {
    me: '/auth/me',
    login: '/auth/login',
    register: '/auth/register',
  },
};

const handleErrorResponse = async (response: Response, accessToken?: string) => {
  const res: Pick<ErrorResponse, 'code' | 'message'> = await response
    .json()
    .catch(() => 'Algo salió mal.');

  if (response.status === 401) {
    res.message = accessToken
      ? 'Su autorización ha caducado! inicie sesión nuevamente.'
      : 'Por favor ingresa primero.';
    if (isSSR()) redirect('/');
    else window.location.reload();
  }

  if (response.status === 429) {
    res.message = 'Lo sentimos! Por favor intenta nuevamente en unos minutos';
  }

  return res;
};
