import { useCallback } from 'react';
// redux
import { useSelector } from 'react-redux';
import { configurationSelector } from '@/redux/slices/configurations';
// routes
import { modules } from '@/routes/paths';

export function usePathActivation() {
  const { configuration } = useSelector(configurationSelector);

  const checkPath = useCallback(
    /**
     *
     * @param _path_name
     * @returns status of allowed
     */
    (_path_name: string) => {
      const denied_paths = Object.entries(configuration?.modules || {})
        .filter(([key, value]) => !value)
        .map(([key]: any) => (modules as any)[key]);
      const is_denied = denied_paths.includes(_path_name);

      return !is_denied;
    },
    [configuration?.modules]
  );

  return {
    checkPath,
  };
}
