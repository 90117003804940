// routes
import { paths } from 'src/routes/paths';
import { BrowserName } from '@/utils/browser';
import { toBoolean } from './utils';

// API
// ----------------------------------------------------------------------
export const is_test = toBoolean(process.env.NEXT_PUBLIC_TEST, false);

// backend
export const HOST_API = process.env.NEXT_PUBLIC_HOST_API || '';
export const TEST_VER = process.env.NEXT_PUBLIC_TEST_VERSION || null;
// OTP
export const HOST_OTP = process.env.NEXT_PUBLIC_HOST_OTP || '';
export const OTP_API_KEY = process.env.NEXT_PUBLIC_OTP_API_KEY || '';

export const INTERNAL_ADMIN = {
  email: process.env.NEXT_PUBLIC_INTERNAL_ADMIN_USER || '',
  password: process.env.NEXT_PUBLIC_INTERNAL_ADMIN_PASSWORD || '',
};
export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || '';
export const introUrl = process.env.NEXT_PUBLIC_INTRO_URL || '';
export const whatsappNumber = process.env.NEXT_PUBLIC_WP_PHONE || '';

// goboarding
export const GOBOARDING_CONFIGURATION = {
  key: process.env.NEXT_PUBLIC_GOBOARDING_API_KEY_NAME,
  secret: process.env.NEXT_PUBLIC_GOBOARDING_API_KEY_SECRET,
};

// google-map
export const GOOGLE_MAP_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || '';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.home; // as '/dashboard/profile'

// pwa
export const enabledPWAInstallerBanner = toBoolean(
  process.env.NEXT_PUBLIC_ENABLE_PWA_INSTALL,
  false
);

// Web Push
export const push_public_key = process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY || '';
export const push_private_key = process.env.WEB_PUSH_PRIVATE_KEY || '';

export const SECRET_KEY = process.env.NEXT_PUBLIC_SECRET_KEY || '';
export const BRANCH_NAME = process.env.NEXT_PUBLIC_BRANCH_NAME || '';

// cookies
export const cookies = {
  pwaInstallDismissed: {
    name: 'pwa_install_dissmissed',
  },
  showAppUpdated: {
    name: 'show_app_updated',
  },
  slideshowShown: {
    name: 'slideshow_shown',
  },
  landingShown: {
    name: 'landing_shown',
  },
  accessToken: {
    name: 'access_token',
  },
};

export const browserPWACompatility: Record<BrowserName, boolean> = {
  chrome: true,
  edge: true,
  firefox: false,
  opera: true,
  safari: false,
  samsung_internet: true,
  microsoft_edge_chromium: false,
  microsoft_internet_explorer: false,
  unknown: false,
};
