import api, { API_ENDPOINTS, internalApi } from '@/utils/api';
import { AuthUser } from '@/types/users';
import { decryptData } from '@/utils/crypto';
import { GoboardingAuthResponse, GoboardingAuthToken } from '../types/auth';

// ----------------------------------------------------------------------

export const getGoboardingAuthLink = async (
  email: string,
  opts: RequestInit = {}
): Promise<string | null> =>
  internalApi.post('/auth/initialize', {
    params: {
      email,
    },
    next: {
      revalidate: 0,
    },
    ...opts,
  });

export const getFaceRegistrationLink = (): Promise<string> =>
  internalApi.post('/auth/face_registration');

export const postLogin = (email: string, password: string): Promise<GoboardingAuthToken> =>
  api.post(API_ENDPOINTS.auth.login, {
    params: {
      email,
      password,
    },
  });

/**
 * get identity information (internal-server)
 *
 * @param transactionId
 * @roles `super-admin`
 * @returns
 */
export const getIdentityInfo = async (transactionId: string): Promise<GoboardingAuthResponse> =>
  api.get(`/onboarders/${transactionId}`, {
    requestRole: 'super-admin',
  });

export const getAuthUser = async (): Promise<AuthUser> => {
  const response = await internalApi.get(API_ENDPOINTS.auth.me);
  const decryptedResponse: AuthUser = decryptData(response);
  return decryptedResponse;
};
