// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: '/login',
    register: '/register',
    registerMarkets: '/register/markets',
    forgot: '/forgot',
    verify: '/verify',
    changePassword: '/change-password',
    logout: '/logout',
  },
  location: '/location',
  // about: '/about',
  starter: '/starter',
  landingWeb: '/landing-web',
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    home: `${ROOTS.DASHBOARD}/home`,
    about: `${ROOTS.DASHBOARD}/about`,
    profile: {
      root: `${ROOTS.DASHBOARD}/profile`,
      update: `${ROOTS.DASHBOARD}/profile/update`,
    },
    marketQr: {
      root: `${ROOTS.DASHBOARD}/market-qr`,
      cognito: (marketId: number) => `${ROOTS.DASHBOARD}/market-qr/cognito/${marketId}`,
    },
    orders: `${ROOTS.DASHBOARD}/orders`,
    coupons: `${ROOTS.DASHBOARD}/coupons`,
    configurations: `${ROOTS.DASHBOARD}/configurations`,
    intelliMarkets: `${ROOTS.DASHBOARD}/intelli-markets`,
  },
};

export const modules = {
  acceso_qr: paths.dashboard.marketQr.root,
  mis_compras: paths.dashboard.orders,
  editar_perfil: paths.dashboard.profile.update,
  configuraciones: paths.dashboard.configurations,
  listado_tiendas: paths.dashboard.intelliMarkets,
  cambiar_password: paths.auth.changePassword,
  mis_intelli_puntos: paths.dashboard.coupons,
};
