import api from '@/utils/api';
import { User, CreateUserResponse, UpdateUserResponse } from '@/types/users';

/**
 * Create a new user (internal-server)
 *
 * @param user
 * @roles `super-admin`
 * @returns
 */
export const postUsers = (newUser: Partial<User>): Promise<CreateUserResponse> =>
  api.post('/users', {
    params: newUser,
    requestRole: 'super-admin',
  });

export const updateProfilePicture = (image_profile: string): Promise<UpdateUserResponse> =>
  api.post('/users/upload/picture', {
    params: {
      image_profile,
    },
  });

export const updateUsers = (id: number, updateUser: Partial<User>): Promise<UpdateUserResponse> =>
  api.patch(`/users/${id}`, {
    params: updateUser,
    next: {
      revalidate: 0,
    },
  });

export const getUser = (id: number): Promise<User> =>
  api.get(`/users/${id}`, {
    next: {
      revalidate: 0,
    },
  });
