import { createSlice } from '@reduxjs/toolkit';
import { Configuration, ConfigurationStateProps } from '@/types/configurations';

const initialState: ConfigurationStateProps = {
  configuration: null,
};

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setConfiguration: (state, action: { payload: Configuration | null }) => {
      state.configuration = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setConfiguration } = slice.actions;

// Selector
export const configurationSelector = (state: { configurations: ConfigurationStateProps }) =>
  state.configurations;
