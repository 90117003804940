export class CustomError extends Error {
  code: string;

  status: number;

  constructor(
    message = 'Algo salió mal.',
    options?: {
      status?: number;
      code?: string;
    } & ErrorOptions
  ) {
    super(message);
    this.name = options?.code || '';
    this.code = options?.code || '';
    this.status = options?.status || 500;
  }
}
